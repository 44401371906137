export const COUPONS = {
  NO_DISCOUNT: 'NO_DISCOUNT',
  PEGN25: 'PEGN25',
  BLACKFRIDAY: 'BLACKFRIDAY',
  NIVERCLARICE: 'NIVERCLARICE',
  LINGUAPT50: 'LINGUAPT50',
  ANUAL50: 'ANUAL50',
  ANUAL40: 'ANUAL40',
  CLARICE60: 'CLARICE60',
  CLARICE50: 'CLARICE50',
  WS50: 'WS50',
  NIVER40: 'NIVER40',
  NIVER60: 'NIVER60'
};

export const IsBlackFriday = () => {
  // // // const now = new Date();

  // const start = new Date(2022, 10, 14, 0, 0, 0);
  // // const end = new Date(2022, 10, 25, 23, 59, 0);

  // const start = new Date(2022, 10, 21, 0, 0, 0);
  // const end = new Date(2022, 10, 25, 23, 59, 0);

  return false;

  // const response =
  //   now.getTime() >= start.getTime() && now.getTime() <= end.getTime();

  // console.log('DATE: ', { start, end, now, response });

  // IS BLACK WEEK
  // return response;

  // console.log('ACTIVE COUPON: ', ACTIVE_COUPON);
};

// export const ACTIVE_COUPON = IsBlackFriday()
//   ? COUPONS.BLACKFRIDAY
//   : COUPONS.ANUAL50;

export const ACTIVE_COUPON = COUPONS.ANUAL40;
